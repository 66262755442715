<template>
  <vs-tabs v-model="activeTab" :position="isSmallerScreen ? 'top' : 'left'" class="tabs-shadow-none" id="profile-tabs" :key="isSmallerScreen">
    <vs-tab icon-pack="feather" icon="icon-shopping-bag" :label="!isSmallerScreen ? $t('eCommerce Store') : ''">
      <div class="md:ml-4 md:mt-0 mt-4 ml-0">
        <merchant-api-keys :merchantStatus="merchantStatus" @APIKey="APIKey = $event"/>
        <vx-card class="mb-base text-center">
          <div class="lg:flex justify-evenly block">
            <div class="text-center cursor-pointer">
              <img @click="showInstructions('shopify')" class="ml-auto mr-auto" src="../../assets/images/pages/shopify.jpg"/>
              <vs-button @click="showInstructions('shopify')">Click for instructions</vs-button>
            </div>
            <div class="cursor-pointer">
              <img @click="showInstructions('woo-commerce')" class="ml-auto mr-auto" src="../../assets/images/pages/woo-commerce.png"/>
              <vs-button @click="showInstructions('woo-commerce')">Click for instructions</vs-button>
            </div>
            <div class="cursor-pointer">
              <img @click="showInstructions('magento')" class="ml-auto mr-auto" src="../../assets/images/pages/magento.png"/>
              <vs-button @click="showInstructions('magento')">Click for instructions</vs-button>
            </div>
          </div>
        </vx-card>
        <vx-card v-if="activeIntegrationsInstructions !== ''">
          <shopify-integration :APIKey="APIKey" v-if="activeIntegrationsInstructions === 'shopify'"/>
          <magento-integration v-if="activeIntegrationsInstructions === 'magento'"/>
          <woo-commerce-integration v-if="activeIntegrationsInstructions === 'woo-commerce'"/>
        </vx-card>
      </div>
    </vs-tab>
    <vs-tab @click="showEvents = false" icon-pack="feather" icon="icon-anchor" :label="!isSmallerScreen ? $t('Webhooks') : ''">
      <div class="tab-general md:ml-4 md:mt-0 mt-4 ml-0">
        <webhook-events v-if="showEvents"/>
        <user-webhooks :merchantStatus="merchantStatus" v-else @showEvents="showEvents = $event"/>
      </div>
    </vs-tab>
  </vs-tabs>
</template>

<script>
import MerchantApiKeys from '../pages/user-settings/MerchantAPIKeys.vue'
import WooCommerceIntegration from './integrations/WooCommerceIntegration.vue'
import ShopifyIntegration from './integrations/ShopifyIntegration.vue'
import MagentoIntegration from './integrations/MagentoIntegration.vue'
import UserWebhooks from '../pages/user-settings/webhooks/UserWebhooks.vue'
import WebhookEvents from '../pages/user-settings/webhooks/WebhookEvents.vue'
import common from '../../assets/utils/common'

export default {
  data () {
    return {
      APIKey: '',
      activeTab: 0,
      activeTabNameDic: {'ecommerce-store': 0, 'webhooks': 1},
      activeTabDic: {0: 'ecommerce-store', 1: 'webhooks'},
      activeIntegrationsInstructions: '',
      showEvents: false,
      merchantStatus: ''
    }
  },
  watch: {
    'showEvents' (val) {
      if (val === false) {
        this.$router.push({
          params: {
            tab: this.activeTabDic[this.activeTab]
          },
          query: {
            webhookID: this.$route.query.webhookID,
            page: 1
          }
        }).catch(() => {})
      } else {
        this.$router.push({
          params: {
            tab: this.activeTabDic[this.activeTab]
          },
          query: {
            webhookID: this.$route.query.webhookID,
            filter: this.$route.query.filter,
            page: 1
          }
        }).catch(() => {})
      }
    },
    '$route.query.filter' () {
      if (this.$route.query.filter === undefined) {
        this.showEvents = false
      } else {
        this.showEvents = true
      }
    },
    '$route.query.page' () {
      this.$router.push({
        params: {
          tab: this.activeTabDic[this.activeTab]
        },
        query: {
          filter: this.$route.query.filter,
          webhookID: this.$route.query.webhookID,
          page: this.$route.query.page ? this.$route.query.page : 1
        }
      }).catch(() => {})
    },
    'activeTab' () {
      this.showEvents = false
      this.$router.push({
        params: {
          tab: this.activeTabDic[this.activeTab]
        },
        query: {
          filter: this.$route.query.filter,
          webhookID: this.$route.query.webhookID,
          page: this.showEvents ? this.$route.query.page : 1
        }
      }).catch(() => {})
    }
  },
  methods: {
    showInstructions (name) {
      this.activeIntegrationsInstructions = name 
    }
  },
  computed: {
    isSmallerScreen () {
      return this.$store.state.windowWidth < 768
    }
  },
  components: {
    MerchantApiKeys,
    ShopifyIntegration,
    MagentoIntegration,
    WooCommerceIntegration,
    UserWebhooks,
    WebhookEvents
  },
  created () {
    this.offset = (this.currentPage - 1) * this.maximumItems
    this.activeTab = this.$route.params.tab ? this.activeTabNameDic[this.$route.params.tab] : '0'
    const merchantData = common.checkMerchantInLocalStorage(this)
    let user = {}
    merchantData.then(results => {
      user = results.merchantData
      this.merchantStatus = user.status
    })
  }
}
</script>